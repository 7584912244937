.services-list {
  position: relative;
  padding: 0 $column;
  background: $white;
  color: $black;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    font-size: 2.255555rem;
    padding: 1.5rem 0;
    border-bottom: 2px solid $black;
    font-weight: bold;

    &:first-child {
      padding-top: 0;
    }
  }

  a:not(.button) {
    color: $black;
    display: block;
    position: relative;
  }
}

@include tablet() {
  .services-list ul {
    li {
      font-size: 1.75rem;
    }
  }
}

@include mobile() {
  .services-list ul {
    margin: 0 auto;

    li {
      font-size: 1.125rem;
      padding: 1.25rem 0.25rem;
    }
  }
}
