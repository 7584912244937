$arrowHeight: 2.5rem;
$overlay-dark: $grey-darkest;
$overlay-light: $white;
// Take out the space of the arrows out of the width so that it aligns.
$content-with-arrows: calc(100% - #{$column * 2} - #{$arrowHeight * 2});

.headline {
  position: relative;
  width: 100%;
  overflow: hidden;

  .static-text {
    position: absolute;
    z-index: $z-above;
    padding: calc(#{$column} / 2) $column;
    bottom: 0;
    width: 100%;
    margin: 1rem 0;
    @include centerAbsolute('horizontal');

    .title {
      font-weight: 900;
      line-height: 4rem;
      margin: 1rem 0;

      a {
        color: $white;
      }
    }

    .subtitle,
    .strap {
      font-size: 1.375rem;
      line-height: 1rem;
      margin: 0;
    }
  }

  .headline-slider,
  .slide {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .headline-slider {
    height: calc(100vh - #{$nav-height});
  }

  .content {
    padding: calc(#{$column} / 2) $column;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;

    .title {
      margin: 0;
      user-select: none;
      font-weight: normal;
    }

    &.center {
      justify-content: center;

      &.has-arrows {
        // Take out the space of the arrows out of the width so that it aligns.
        width: $content-with-arrows;
      }

      .title {
        font-family: $font-snowflake-sans;
        font-size: 1.625rem;
        line-height: 3rem;
      }

      .strap {
        text-transform: uppercase;
        font-size: 1.375rem;
        margin-bottom: -0.5rem;
      }

      .subtitle {
        font-size: 1.375rem;
      }
    }

    // For extra long entry titles make sure they do not overlap with the arrows.
    &:not(.center) .title {
      &.small {
        font-size: 3rem;
        line-height: 3rem;
      }
    }

    .subtitle {
      font-size: 1.875rem;
      letter-spacing: 0.025rem;
      user-select: none;
    }

    .strap {
      font-size: 1.25rem;
      margin: 0;
    }

    .link {
      font-size: 1rem;
      line-height: 3.625rem;
      margin: 2rem 0 1.5rem 0;
      border: 1px solid $white;
      text-transform: uppercase;
      padding: 0 1.8125rem;
      user-select: none;
    }

    .more-arrow-icon {
      fill: $white;
      stroke: none;
      width: 15px;
      height: 14px;
      margin-left: 1rem;
    }
  }

  .title {
    font-size: 4.3rem;
  }

  .bullets {
    padding: 1rem 0;
    margin: 0;
    list-style: none;
    cursor: default;

    .bullet {
      padding: 0.5rem 0;
      display: inline-block;
      width: 2rem;
      height: 0.25rem;
      cursor: pointer;
      opacity: 0.4;
      border-radius: 0.1rem;
      background-color: $white;
      background-clip: content-box;
      background-size: 200% 200%;
      background-position: -100% 0;

      &.active {
        // NOTE: Animation duration is set in js to match to slide speed.
        opacity: 1;
        pointer-events: none;
        animation-name: loader-bar;
        animation-timing-function: linear;
        background-color: transparent;
        background-image: linear-gradient(to right, rgba($white, 0.4) 50%, $white 50%);
      }

      @keyframes loader-bar {
        0% {
          background-position: 0 0;
        }
        100% {
          background-position: -100% 0;
        }
      }
    }
  }

  .controls {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    outline: none;

    button {
      @include sliderArrow($column, $white);

      &:focus-visible {
        outline: $outline;
      }
    }
  }

  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent
      linear-gradient(
        1turn,
        rgba($grey-darkest, 80%),
        rgba($grey-darkest, 40%) 60%,
        rgba($grey-darkest, 0)
      )
      0 0 no-repeat padding-box;
    z-index: $z-low;
  }

  .content,
  .overlay {
    transition: opacity $anim-dur-headline $anim-func-headline;
  }

  picture {
    // Making sure we don't see the image outside the headline div.
    position: absolute;
    clip: rect(0, auto, auto, 0);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    pointer-events: none;
  }

  .main-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: $z-headline;
    object-fit: cover;
    transition: top 60ms ease-out;

    // Override an opacity set by the tiny-slider lazy-load feature.
    opacity: 1 !important;
  }

  .default-image {
    z-index: $z-headline;
    margin: 0;
    // Compensate for the navigation height
    padding: calc(#{$column} + 80px) 0 0 0;

    .snowflake-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
      background: rgba($black, 0.95);

      .snowflake {
        right: 0;
        top: 20%;
        transform: translateX(30%);
        position: absolute;
        height: 65%;
        aspect-ratio: 1;
        opacity: 0.7;
        color: $black-light;
      }
    }
  }

  .default-image + .static-text {
    position: relative;
    padding-top: $column;
    margin: 0;

    .title,
    .subtitle,
    a {
      color: $white;
    }
  }

  // When images are light we use dark letters.
  &.light {
    .controls button {
      &::before,
      &::after {
        background-color: $black;
      }
    }

    .content {
      .link {
        border-color: $black;
      }

      .more-arrow-icon {
        fill: $black;
      }
    }

    .bullets .bullet {
      background-color: $black;
      background-clip: content-box;

      &.active {
        background-color: transparent;
        background-image: linear-gradient(to right, rgba($black, 0.4) 50%, $black 50%);
      }
    }

    .overlay {
      background: transparent linear-gradient(360deg, #ffffff 0%, #ffffff3b 29%, #ffffff00 100%) 0 0
        no-repeat;

      &.no-color {
        background-image: linear-gradient(
          to top,
          $overlay-light 0%,
          rgba($overlay-light, 0.5) 10%,
          transparent 25%
        );
      }
    }
  }
}

@include short() {
  .headline .content.has-arrows {
    // Take out the space of the arrows out of the width so that it aligns.
    // On short screens non centered content ends up overlapping with the arrows if there is no padding.
    width: $content-with-arrows;
  }
}

@include mobile() {
  .headline {
    .static-text {
      .title {
        font-size: 2rem;
        line-height: 2.5rem;
        margin: 0;
      }

      .subtitle,
      .strap {
        font-size: 1.25rem;
        line-height: 2rem;
        font-family: $font-snowflake-sans;
      }
    }

    .content {
      &.center {
        &.has-arrows {
          width: 100%;
        }

        .title {
          margin: 0 0 10px 0;
        }
      }

      .title {
        font-size: 2rem;
        line-height: 2.5rem;
      }

      .subtitle {
        font-size: 1.75rem;
      }
    }

    .static-text {
      .title {
        margin-bottom: 1rem;
        line-height: 2.5rem;
      }
    }

    .title {
      font-size: 2rem;
      overflow-wrap: anywhere;
    }

    .controls {
      display: none;
    }

    .default-image svg.mark {
      transform: rotate(-11deg) translate(-40%, -60%);
    }
  }
}

@include extraWideTall() {
  .about,
  .contact {
    .headline .content .title {
      width: 60%;
    }
  }
}
