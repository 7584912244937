.download-section,
.signup-section,
.signup_comms-section {
  position: relative;
  overflow: hidden;
  background: $white;
  color: $black;

  .title {
    font-size: 2.25rem;
    letter-spacing: 0.5px;
    margin: 0 0 1.6rem 0;
    font-weight: normal;
  }

  .text {
    font-size: 1.12rem;
    line-height: 1.625rem;
  }

  .text-holder button {
    padding: 0.9rem 2rem;
    margin: 0.75rem 0;

    .download-text {
      margin: 0;
    }
  }

  .button-content {
    margin: 0 0.25rem;
  }

  @include mobile() {
    .text {
      font-size: 1rem;
    }
  }
}
