.location {
  display: flex;
  justify-content: flex-start;
  margin-left: auto;
  margin-right: auto;

  .divider {
    box-sizing: border-box;

    &:not(:last-child) {
      padding-right: 4rem;
    }

    .hq-title {
      font-weight: normal;
      font-size: 2.25rem;
      line-height: 2.5rem;
      margin-top: 0;

      a {
        color: inherit;
        border-bottom: 2px solid;
        padding-bottom: 2px;
      }
    }

    .address-title {
      line-height: 1.38rem;
      padding: 0;
      margin: 0;
    }

    .street {
      line-height: 1.38rem;
      padding: 0;
      margin: 0 0 0.8rem 0;
    }

    .phone {
      display: block;
      margin: 0 0 0.8rem 0;
      color: inherit;

      // Colombia has a crazy long phone field. Make it wrap.
      white-space: normal;
    }

    .contact-link {
      color: $blue-wt;
      font-weight: bold;
      padding: 0 0 0.1rem 0;
      border-bottom: 4px solid $blue-wt;
      display: inline-block;
    }

    .office-link {
      color: $black;
      border-bottom: 2px solid $black;
      display: inline-block;
      margin: 0 0 1.2rem 0;
      text-transform: capitalize;

      svg {
        fill: $black;
        max-height: 13px;
        width: 13px;
        margin: 0 0 0 0.3rem;
      }
    }

    .region,
    .city {
      text-transform: uppercase;
      margin: 0;
      font-size: 1.38rem;
    }

    .city {
      font-weight: normal;
    }
  }

  .buttons {
    .button {
      // Don't let button grow to the parent width with default block display mode.
      display: inline-block;
    }
  }

  &:not(.single) {
    .divider:first-child {
      width: 35%;
    }
    .divider:nth-child(2) {
      width: 35%;
    }
  }

  @include tablet() {
    .divider:not(:last-child) {
      padding-right: 2rem;
    }
  }
}

@include mobile() {
  .location {
    flex-direction: column;

    .divider {
      padding: 0 !important;
      width: 100% !important;

      .hq-title {
        font-size: 1.12rem;
        line-height: 1.6rem;
        font-family: $font-snowflake-sans;
        margin: 0 0 0.8rem 0;
      }

      .street {
        margin: 0 0 1rem 0;
      }

      .phone {
        margin: 0 0 2rem 0;
      }
    }
  }
}
