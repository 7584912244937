// TODO: make background color customizable for the accordion
.accordion-section {
  position: relative;
  background-color: inherit;
  color: inherit;

  &.blue {
    background: $blue-wt;
    color: $white;

    .accordion-container .accordion-label .accordion-trigger {
      &:-moz-focusring {
        outline: 2px solid $white;
      }

      &:focus-visible {
        outline: 2px solid $white;
      }
    }
  }

  &.headline {
    margin: 0;
  }

  .accordion-container {
    background: inherit;
    color: inherit;

    .accordion-label {
      font-size: 2.25rem;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      margin: 0;

      .accordion-trigger {
        position: relative;
        background: transparent;
        font-size: 2.25rem;
        font-family: $font-snowflake-sans;
        color: inherit;
        text-align: left;
        width: 100%;
        outline: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        padding: 0 2.5rem 0 0;
        text-transform: none;

        &[aria-expanded]:after {
          content: '';
          transform: rotate(-135deg);
        }

        &:after {
          @include arrow(1.2rem, 3px);
          right: 0.3rem;
          position: absolute;
        }

        &:-moz-focusring {
          outline: 2px solid $blue-wt;
        }

        &:focus-visible {
          outline: 2px solid $blue-wt;
        }

        .accordion-title {
          outline: 0;
        }
      }
    }

    .accordion-description {
      background: inherit;
      color: inherit;
      margin-top: -1px;
      overflow: hidden;
      height: 100%;
      max-height: 3000px;
      position: relative;
      font-size: 1.125rem;
      line-height: 1.625rem;
      padding-top: 1.5rem;

      p {
        padding: 0 1.5rem;

        a {
          color: inherit;
          border-bottom: 2px solid;
        }
      }

      &.collapse-enter-active,
      &.collapse-leave-active {
        transition: max-height 250ms ease-in-out, padding 250ms ease-in-out;
      }

      &.collapse-enter,
      &.collapse-leave-to {
        height: 100%;
        max-height: 0;
        padding: 0;
      }
    }

    &-item {
      padding: 1.5rem 0;

      &.first {
        padding-top: 0;
      }

      &:not(:last-child) {
        border-bottom: 2px solid;
        border-color: inherit;
      }
    }
  }
}

@include tablet() {
  .accordion-section {
    .accordion-container {
      padding: 0;

      .accordion-trigger {
        outline: 0;
      }
    }
  }
}

@include mobile() {
  .accordion-section {
    .accordion-container {
      width: 100%;

      .accordion-label {
        font-size: 1.125rem;
        line-height: 1.625rem;
        padding-right: 1.5rem;

        &:after {
          border-bottom: 2px solid;
          border-right: 2px solid;
          width: 0.6rem;
          height: 0.6rem;
        }

        .accordion-trigger {
          outline: none;
          font-size: 1.125rem;
        }
      }

      input:checked ~ .accordion-description {
        transition: max-height 0.75s ease-in-out, padding 0.5s ease-in-out;
      }
    }
  }
}
