.people-section {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  .people-content {
    width: 100%;

    .buttons.inline {
      margin-top: 1.25rem;
    }
  }

  .people {
    box-sizing: border-box;
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;

    .person {
      display: flex;
      align-items: center;
      width: 49%;
      margin: 0 0 3.75rem 0;
      color: $black;

      .person-img {
        background-color: rgba(255, 255, 255, 0.2);
        position: relative;
        width: 9rem;
        height: 9rem;
        display: block;
      }

      .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 0 0 1rem;
        height: 100%;

        .person-detail {
          display: flex;
          align-items: center;
          height: 100%;
        }

        h3 {
          font-weight: normal;
          font-size: 2.25rem;
          line-height: 2.5rem;
          letter-spacing: 0.5px;
          margin: 0 0 0.3rem 0;
        }

        h4 {
          margin: 0;
          font-size: 1.12rem;
          font-weight: normal;

          &.office {
            text-transform: uppercase;
            letter-spacing: 3px;
          }
        }
      }
    }
  }
}

@include tablet() {
  .people-section {
    .people {
      .person {
        width: 100%;
      }
    }
  }
}

@include mobile() {
  .people-section {
    .people .person .info h4.office {
      letter-spacing: 0;
      padding-top: 0.5rem;
    }

    .people {
      width: 100%;
      flex-direction: column;

      .person {
        width: 100%;
        padding: 0;
        margin: 0 0 3rem 0;
        align-items: flex-start;

        .person-img {
          height: 5.75rem;
          width: 5.75rem;
        }

        .info {
          margin: 0 0 0 0.8rem;

          h3 {
            font-size: 1.5rem;
            line-height: 1.75rem;
          }

          h4 {
            font-size: 0.8rem;
            line-height: 1rem;
          }
        }

        &:hover {
          background-color: none !important;
        }

        &:last-child {
          margin: 0;
        }
      }
    }

    .buttons.inline {
      margin: 3rem auto;
    }

    .people-content {
      .buttons.inline {
        margin-top: 0;
      }
    }
  }
}
