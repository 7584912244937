.buttons {
  position: relative;

  &.inline {
    display: flex;
    justify-content: center;
    margin: $section-space auto auto;
  }
}

main.content > .buttons.inline {
  margin: 0 auto;
  padding: 0 0 $section-space 0;
}

button,
.button {
  position: relative;
  display: block;
  text-align: center;
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;

  &:not(.disabled),
  &:not([disabled]) {
    cursor: pointer;
  }

  &:not(.link) {
    padding: 1rem 2.3rem;
    background: $black;
    color: $white;
  }

  &:not(.link).light {
    background: $white;
    color: $black;
  }

  &.link {
    padding: 0;
    background-color: transparent;
    display: inline-block;
    font-family: $font-snowflake-sans;
  }

  svg {
    width: 1rem;
    height: 1rem;
  }
}

button {
  border: none;

  &:disabled {
    opacity: 0.8;
  }

  &.play {
    margin: 0;
    border: 2px solid $white;
    border-radius: 2rem;
    padding: 2rem 5rem !important;
    background: rgba(0, 0, 0, 0.5) !important;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

    &:before {
      @include centerAbsolute();
      transform: translate(-25%, -50%);
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-top: 0.75rem solid transparent;
      border-bottom: 0.75rem solid transparent;
      border-left: 1rem solid $white;
      box-sizing: border-box;
    }

    @media (hover: hover) {
      &:hover {
        border: 2px solid $white;
        background: rgba(0, 0, 0, 0.7) !important;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);

        &:before {
          border-left: 1rem solid $white;
        }
      }
    }
  }
}

@include mobile() {
  .buttons {
    &.inline {
      margin: $section-space-mobile auto auto;
    }
  }
}
