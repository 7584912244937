.text-section,
.image-with-text-section,
.video-with-text-section {
  position: relative;
  overflow: hidden;
  background: $white;
  color: $black;

  .title {
    font-size: 2.25rem;
    letter-spacing: 0.5px;
    margin: 0 0 1.6rem 0;
    font-weight: normal;
  }

  .text {
    font-size: 1.12rem;
    line-height: 1.625rem;
  }

  @include mobile() {
    // Fixing underlines (border bottom) for liked titles.
    .text {
      h2,
      h3,
      h4 {
        > a {
          line-height: 1.85rem;
        }
      }
    }

    .title {
      font-size: 1.7rem;
    }
  }
}
