@import "_common.scss";
#main-nav {
  display: none;
}

#site-footer {
  display: none;
}

body {
  background: $purple-vml;

  #site-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  &.purple {
    background: $purple-vml;
  }

  &.black,
  &.black-light,
  &.black-snowflake {
    background: $black;
  }

  &.white {
    background: $white;
  }

  &.light .links a {
    border: 1px solid $black;
  }

  &.dark .links a {
    border: 1px solid $white;
  }
}

.error-page {
  display: flex;
  min-height: 100vh;
  overflow-y: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  #error-message {
    padding: 5rem;
    position: relative;
    max-width: 500px;

    h1 {
      font-family: 'SnowflakeSans inline', Arial, sans-serif;
      font-size: 5rem;
      padding: 0 0 1rem 0;
      margin: 0;
      font-weight: normal;
    }

    h5 {
      font-size: 3rem;
      padding: 0 0 2.5rem 0;
      margin: 0;
    }

    .copy-text {
      font-size: 1.5rem;
      padding-bottom: 1.5rem;
      line-height: 2rem;
    }

    .error-it {
      font-size: 1.125rem;

      a {
        text-decoration: underline;
        font-weight: bold;
      }
    }

    .links {
      padding: 0 0 2rem 0;
      font-size: 0.87rem;
      font-weight: bold;
    }

    .links a {
      text-decoration: none;
      padding: 0.75rem 1rem;
      display: inline-block;
      cursor: pointer;
      margin: 0 1rem 0 0;
      line-height: 1.3rem;
      text-transform: uppercase;
    }

    .footer a {
      text-decoration: underline;
    }
  }

  .logo {
    display: block;
    margin-bottom: 4rem;

    svg {
      height: 1.7rem;
      width: 7.18rem;
      display: inline-block;
    }
  }
}

@include tabletAndUp {
  body {
    #img-mobile-top,
    #img-mobile-bottom {
      display: none;
    }

    &.purple {
      .error-page {
        background-image: url('/img/errors/pattern-pink.svg');
        background-size: contain;
      }
    }

    &.black {
      .error-page {
        background-image: url('/img/errors/error2.svg');
        background-size: contain;
      }
    }

    &.black-light {
      .error-page {
        background-image: url('/img/errors/error3.svg');
      }
    }

    &.black-snowflake {
      .error-page {
        background-image: url('/img/errors/error4.svg');
      }
    }

    &.white {
      .error-page {
        background-image: url('/img/errors/error5.svg');
      }
    }
  }
}

@include mobile {
  body {
    &.purple {
      #img-mobile-top {
        background-image: url('/img/errors/bg-pink-top.svg');
      }

      #img-mobile-bottom {
        background-image: url('/img/errors/bg-pink-bottom.svg');
      }
    }

    &.black {
      #img-mobile-top {
        background-image: url('/img/errors/mobile-top.svg');
      }

      #img-mobile-bottom {
        background-image: url('/img/errors/mobile-bottom.svg');
      }
    }

    &.black-snowflake {
      h5 {
        background-image: url('/img/errors/error4.svg');
      }
    }

    &.black-light {
      h5 {
        background-image: url('/img/errors/error3.svg');
      }
    }

    &.white {
      h5 {
        background-image: url('/img/errors/error5.svg');
      }
    }

    &.black-snowflake,
    &.black-light,
    &.white {
      h5 {
        padding: 2rem 0 2rem 0;
        line-height: 3rem;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        min-height: 173px;
        display: flex;
        align-items: center;
      }

      #content {
        background-image: none;
      }
    }
  }

  .error-page {
    .logo {
      margin-bottom: 1rem;
      margin-top: 1rem;
    }

    #error-message {
      justify-content: center;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      max-width: none;
      padding: 0 2rem 0 2rem;

      .copy-text {
        padding-bottom: 0.5rem;
      }

      h5 {
        font-size: 2.6rem;
        padding: 2rem 0 2rem 0;
        line-height: 3rem;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }

      h1 {
        font-size: 3rem;
        padding: 0 0 0.6rem 0;
      }

      p {
        font-size: 1rem;
        padding: 0 0 4rem 0;
      }

      .links {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        a {
          margin: 0 0 1.5rem 0;
          padding: 0.6rem;
          width: fit-content;
        }
      }

      #img-mobile-top,
      #img-mobile-bottom {
        height: 20vh;
        background-repeat: no-repeat;
        background-size: contain;
        z-index: -1;
        position: absolute;
      }

      #img-mobile-top {
        background-position: top;
        width: calc(100vw - 4rem);
        top: 0;
      }

      #img-mobile-bottom {
        background-position: bottom;
        width: calc(100vw - 4rem);
        bottom: 0;
      }
    }
  }
}
