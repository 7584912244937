.image-with-text-section,
.video-with-text-section {
  position: relative;
  overflow: hidden;
  color: $black;

  &.entry-text {
    ul {
      list-style-type: disc;
      margin-left: 1.1rem;

      li {
        padding: 0 0 1rem 0;

        &:before {
          display: none;
        }
      }
    }

    .landscape {
      margin-right: 3rem;
    }

    &.left ul li {
      margin-left: calc(50% + 2.5rem);
    }
  }

  p {
    font-size: 1.12rem;
    line-height: 1.625rem;

    &:first-of-type {
      margin-top: 0;
    }
  }

  .poster-container {
    position: relative;
  }

  .height-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  figure {
    max-width: calc(50% - 1rem);
    width: 100%;

    figcaption {
      text-align: center;
      width: 100%;
      font-size: 0.75rem;
    }

    img,
    video,
    .jwplayer {
      width: 100%;
      height: auto;
    }
  }

  &.right {
    figure {
      float: right;
      margin: 0 0 0.25rem 2rem;
    }
  }

  &.left {
    figure {
      float: left;
      margin: 0 2rem 0.25rem 0;
    }
  }
}

@include mobile() {
  .image-with-text-section,
  .video-with-text-section {
    figure {
      max-width: 100%;
      float: none;
      margin: 0 0 0.25rem 0 !important;
    }

    &.entry-text.left ul li,
    &.entry-text ul {
      margin: 0 calc(#{$column} / 2);
    }

    &.right,
    &.left {
      figure {
        figcaption {
          margin: 0 0 1rem 0;
        }
      }
    }
  }
}
