.prompt-modal {
  // Introduce scroll on small screens https://jira.uhub.biz/browse/JWTSOLWTCOM-740
  max-height: 98vh;
  overflow: auto !important;

  .container {
    header {
      margin: 3rem 0 1rem 0;

      h2 {
        font-size: 1.2rem;
        text-align: center;
        padding: 0 1rem;
      }
    }

    form {
      width: 80%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;

      input {
        width: 100%;
        margin: 0.5rem 0 1.75rem;
        border: 0;
        border-bottom: 2px solid $black;
        text-align: left;
        border-radius: 0;
        box-sizing: border-box;
        padding: 0 0 0.3rem 0;
        font-size: 1.12rem;

        &:focus {
          outline: none;
          border-bottom: 1px solid $grey-darkest;
        }

        &::placeholder {
          color: $grey-medium;
        }
      }

      button {
        margin: 0.5rem 0 2rem;
        background-color: $grey-darkest;
        color: $white;

        &:hover {
          background-color: $grey-dark;
        }
      }
    }
  }

  .phone {
    max-width: 630px;
    display: flex;

    select {
      width: 160px;
      margin-right: 1rem;
    }
  }

  select {
    position: relative;
    display: block;
    color: $black;
    padding: 0.3rem 0.6rem;
    height: 2.5rem;
    appearance: none;
    border: 1px solid $black;
    background-color: $white;
    border-radius: 0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='10' role='presentation' class='vs__open-indicator'%3E%3Cpath d='M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z'%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 14px 10px;
    cursor: pointer;
    outline: none;

    &#promptIndustry {
      width: 100%;
    }

    &:focus {
      border-color: $grey-darkest;
    }

    &.placeholder {
      color: $grey-medium;
    }

    > option:not([disabled]) {
      color: $black;
    }
  }
}
