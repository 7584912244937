.data-table {
  position: relative;
  overflow: hidden;

  .section-title {
    padding-bottom: 0.6rem;
  }

  .cnt-fc {
    position: relative;
    z-index: $z-above;
    min-height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .snowflake {
    position: absolute;
    width: 100vw;
    height: 100vw;
    left: 0;
    z-index: $z-normal;
    @include centerAbsolute('vertical');
  }

  h2 {
    font-size: 2.7rem;
    line-height: 3.2rem;
    letter-spacing: 1.2px;
    margin: 0;
    font-weight: normal;
  }

  h2 + .content-wrapper {
    margin-top: $section-space / 2;
  }

  .content-wrapper {
    display: flex;
    align-items: flex-start;

    &:empty {
      margin: 0;
    }
  }

  .content {
    margin: 0 6rem 0 0;

    strong {
      letter-spacing: 0.2px;
      margin: 0 0 0.8rem 0;
      display: block;
    }

    a {
      border-bottom: 2px solid;
      padding: 0 0 0.2rem 0;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        margin: 0 0 1rem 0;
      }

      .up-right-arrow svg {
        padding-left: 0.4rem;
        max-height: 13px;
        max-width: 13px;
      }
    }

    &.author a {
      font-weight: bold;
    }
  }

  .button-section {
    margin: 0;
    flex-grow: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: row;

    .button {
      display: block;
      vertical-align: center;
      min-width: 160px;
      text-transform: uppercase;

      &:not(:first-child) {
        margin-left: 1.3rem;
      }

      .share-icon {
        width: 1.2rem;
        height: 1.2rem;
        margin-left: 0.8rem;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}

@include tablet() {
  .data-table {
    h2 {
      font-size: 2.5rem;
      line-height: 3rem;
    }

    .content a {
      line-height: 1.75rem;
    }

    .mark {
      top: 75px;
    }
  }
}

@include mobile() {
  .data-table {
    .cnt-fc {
      min-height: 220px;
    }

    h2 {
      font-size: 1.8rem;
      line-height: 2.3rem;
    }

    h2 + .content-wrapper {
      margin-top: calc(#{$section-space} / 2);
    }

    .button-section {
      flex-direction: column;

      .button:not(:first-child) {
        margin-left: 0;
        margin-top: 1.3rem;
      }
    }

    .content-wrapper {
      flex-direction: column;

      .content a {
        display: inline-block;
      }

      .content,
      .social-connect-icons {
        margin-top: $column;
      }
    }
  }
}
