// Main Site Header
$nav-spacing: $column;
$nav-spacing-small: (calc(#{$column} / 4)) $column;

#main-nav {
  position: relative;
  top: 0;
  width: 100%;
  z-index: $z-nav;
  font-weight: bold;

  .background {
    background: $black;
    border-bottom: 1px solid #4c4c4c;
  }

  .nav-wrapper {
    position: relative;
    width: 100%;
    margin: 0 auto;
    // Moving the top and bottom spacing to the letters to make he hover work preperly.
    padding: 0 $nav-spacing;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    opacity: 1;
    transition: opacity $anim-dur-nav;
    // Must match the size of the nav element.
    height: $nav-height;

    .logo-spacer {
      border-right: 1px solid;
      padding-right: 1rem;
    }
  }

  nav.menu {
    display: flex;
    justify-content: flex-end;
    font-size: 0.75rem;
    padding-bottom: 0;

    a {
      position: relative;
      text-transform: uppercase;
      // Add the padding to the link so that the hover activation for the subnav works properly.
      padding: 2rem 0;
      margin: 0 0.875rem;
      display: block;
      color: $white;

      // Have to use pseudo element for border because we need the height of the element to
      // be 100% of the nav so that the user can select things in the subnav.
      &:after {
        position: absolute;
        width: 100%;
        height: 3px;
        content: '';
        top: 3.3rem;
        left: 0;
      }

      &.active,
      &:hover,
      &:active,
      &.always-active {
        &:after {
          background: $white;
        }
      }

      &.active {
        pointer-events: none;
      }
    }
  }

  .submenu-wrapper {
    position: absolute;
    // Must match the height of the submenu.
    height: 3.6rem;
    left: -100%;
    width: 100%;
    background: $black-light;
    transition-delay: 100ms;
    transition: opacity 300ms ease-in-out;
    overflow: hidden;

    &:hover,
    &.active {
      left: 0;
      opacity: 1;
    }

    .submenu {
      @extend .nav-wrapper;
      position: absolute;
      opacity: 0;
      padding: 1.2rem 0;
      left: -100vw;
      width: 100%;
      top: 0;
      visibility: hidden;
      background: $black-light;

      &.active,
      &:hover {
        opacity: 1;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        visibility: visible;
      }

      &:hover {
        z-index: 1;
      }

      a {
        font-size: 0.75rem;
        opacity: 0.8;
        padding: 0.4rem 0 calc(0.4rem + 3px);
        margin: 0 0.4rem;
        text-transform: uppercase;
        color: $white;
        border-bottom: none;
        border-top: 3px solid transparent;

        &.active,
        &:hover,
        &:active {
          border-bottom: 3px solid $white;
          padding-bottom: 0.4rem;
        }

        &.active {
          pointer-events: none;
        }
      }
    }

    .context {
      a:first-child:after {
        @include arrow(0.5rem, 2px, -45deg);
        position: relative;
        top: 0.05rem;
        right: -0.3rem;
      }
    }
  }

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: $z-low;
    transition: opacity $anim-dur-nav;
    opacity: 1;
  }

  &.floating {
    position: fixed;

    .background {
      opacity: 1;
    }

    // Make the spacing the same on the modal as is on the navigation
    & ~ .modal.navigation-modal .content {
      padding: $nav-spacing-small;
    }
  }

  &.hide {
    pointer-events: none;

    .nav-wrapper,
    .submenu-wrapper,
    .background {
      opacity: 0;
    }
  }

  .logo {
    height: 1.75rem;
    width: 6rem;
    display: block;
    color: $white;
  }

  a.skip-main {
    left: -999px;
    position: absolute;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    z-index: -999;
    outline: none;
    white-space: nowrap;

    &.inactive {
      display: none;
    }

    &:focus,
    &:active {
      color: $white;
      outline: $white;
      left: auto;
      top: auto;
      width: auto;
      height: auto;
      overflow: auto;
      margin: 0.625rem 40%;
      z-index: 999;
    }
  }

  .main-nav-open {
    display: none;
    align-items: center;
    justify-content: center;
    background: none;
    outline: none;
    margin: 0;
    padding-right: 0;
  }

  .main-nav-open,
  .main-nav-close {
    cursor: pointer;
  }

  button > h4 {
    font-family: $font-snowflake-sans;
    font-size: 1rem;
    font-weight: normal;
    text-transform: uppercase;
    margin: 0;
    color: $white;
  }

  .hamburger {
    display: block;
    margin: 0 0 0 0.5rem;
    padding: 0;

    li {
      width: 1.5rem;
      height: 0.2rem;
      margin: 0 0 0.3rem 0;
      display: block;
      background-color: $white;

      &:last-child {
        margin: 0;
      }
    }
  }
}

.modal.navigation-modal {
  $skew-size: 300px;

  position: fixed;
  display: block;
  top: 0;
  left: 200vw;

  text-transform: uppercase;
  transition: left 1000ms ease-in-out, transform 1500ms ease-in-out, visibility 500ms;
  box-sizing: content-box;
  border-width: 0 0 0 $skew-size;
  border-style: solid;
  background-clip: content-box;
  overflow-y: auto;
  overflow-x: hidden;
  visibility: hidden;
  // Fix for chrome android bug miscalculating the width.
  width: calc(100% + 0.1px);
  background-color: $black;

  &.active {
    left: -$skew-size;
    visibility: visible;

    .snowflake-wrapper {
      opacity: 0.7;
    }
  }

  .content {
    padding: calc(#{$nav-spacing} / 2) $nav-spacing;
  }

  .snowflake-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('/img/snowflake-gray.svg');
    background-color: $black;
    background-position: center;
    opacity: 0;
    transition: opacity 1000ms ease-in-out;

    .snowflake {
      position: relative;
      color: $white;
      width: 100vw;
      height: 100vh;
    }
  }

  header,
  nav {
    position: relative;
    z-index: 1;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.5rem;

    .logo {
      height: 2.5rem;
      width: 7.18rem;
      display: block;
      color: $white;
    }

    .main-nav-close {
      color: $white;
      cursor: pointer;
      background: none;
      outline: none;

      .x-icon {
        margin-left: 0.5rem;
        fill: $white;
        width: 1.2rem;
        height: 1.2rem;
      }
    }
  }

  nav {
    a,
    button,
    label {
      text-decoration: none;
      cursor: pointer;
      display: inline-block;
      color: $white;
      font-weight: 700;
      font-size: 1.375rem;
      line-height: 3.75rem;
      text-transform: capitalize;

      &.selected {
        opacity: 1;
      }
    }
  }
}

@include desktopOnly() {
  #main-nav {
    nav.menu a {
      font-size: 0.8rem;
    }

    .submenu-wrapper .submenu a {
      font-size: 0.69rem;
    }
  }
}

@include mobileAndTablet() {
  // For the modal nav turn off pointer events and highlight the active menu item.
  .modal.navigation-modal nav {
    a[href='#'] {
      opacity: 1;
      pointer-events: none;
    }
  }
}

@media screen and (max-width: $main-menu) {
  #main-nav {
    nav.menu {
      display: none;
    }

    .main-nav-open {
      display: flex;
    }

    .nav-wrapper {
      padding: calc(#{$column} / 4) $nav-spacing;
    }

    .submenu-wrapper {
      display: none;
    }
  }
}

@include tablet() {
  .modal.navigation-modal {
    header {
      margin-top: 0.3rem;
    }

    &.modal.active {
      display: block;
    }
  }
}

@include mobile() {
  #main-nav {
    .skip-main {
      display: none;
    }
  }

  .modal.navigation-modal {
    &:before {
      content: '';
      left: -260px;
      border-width: 0 260px 100vh 0;
    }

    header {
      padding-top: calc(#{$nav-spacing} / 2);
    }

    .content {
      margin-top: 0;
    }
  }
}
