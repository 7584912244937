// Define layout media queries
@mixin mobile {
  @media screen and (max-width: #{$tablet - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: $tablet) and (max-width: #{$desktop - 1px}) {
    @content;
  }
}

@mixin tabletAndUp {
  @media screen and (min-width: $tablet) {
    @content;
  }
}

@mixin mobileAndTablet {
  @media screen and (max-width: #{$desktop - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop) {
    @content;
  }
}

@mixin desktopOnly {
  @media screen and (min-width: $desktop) and (max-width: #{$widescreen - 1px}) {
    @content;
  }
}

@mixin widescreen {
  @media screen and (min-width: $widescreen) {
    @content;
  }
}

@mixin extraWideTall {
  @media screen and (min-width: $extrawide) and (min-height: $short) {
    @content;
  }
}

// A wide but short desktop. URGH.
@mixin nicoleDesktop {
  @media screen and (min-width: $widescreen) and (max-height: $short) {
    @content;
  }
}

// More inclusive version of the Nicole desktop. Yuck.
// Applies to desktop and tablet
@mixin short {
  @media screen and (max-height: $short) and (min-width: #{$tablet}) {
    @content;
  }
}

/// Center an absolute Div. and extend additional transformations.
/// @param $dir String vertical, horizontal or both
/// @param $rotate Number The degrees to rotate the element.
/// @param $scale Number Scale of the element, defaults to 1.
@mixin centerAbsolute($dir: 'all', $rotate: 0, $scale: 1) {
  position: absolute;

  @if ($dir == 'vertical') {
    top: 50%;
    transform: translateY(-50%) rotate($rotate) scale($scale);
  } @else if ($dir == 'horizontal') {
    left: 50%;
    transform: translateX(-50%) rotate($rotate) scale($scale);
  } @else {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate($rotate) scale($scale);
  }
}

/// Set different title sizes for text.
/// @param $textSize Size of the text.
/// @param $lineHeight Size of the line-height.
/// @param $difference The size difference for large and small.
@mixin titleSizes($textSize, $lineHeight, $difference: 0.3) {
  &.large {
    font-size: $textSize + $difference;
    line-height: $lineHeight + $difference;
  }

  &.medium {
    font-size: $textSize;
    line-height: $lineHeight;
  }

  &.small {
    font-size: $textSize - $difference;
    line-height: $lineHeight - $difference;
  }
}

@mixin sliderArrow($margin, $color, $arrowHeight: 2.5rem) {
  position: relative;
  z-index: $z-above;
  margin: 0 $margin 0 0;
  width: $arrowHeight;
  height: $arrowHeight * 2;
  border: none;
  outline: none;
  cursor: pointer;
  background: transparent !important;
  transition: opacity linear 100ms;

  &:hover {
    opacity: 0.6;
  }

  &:before,
  &:after {
    opacity: 1;
    content: '';
    height: $arrowHeight;
    width: 3px;
    background: $color;
    position: absolute;
  }

  &::before {
    transform: rotate(-20deg) translate(14px, -#{$arrowHeight * 0.84});
    border-radius: 2px 2px 0 0;
  }

  &::after {
    transform: rotate(20deg) translate(50%, 0);
    border-radius: 0 0 2px 2px;
  }

  &:first-child {
    margin: 0 0 0 $margin;
    transform: rotate(180deg);
  }
}

/// Generate arrow css, defaults to down.
/// @param $size Size of the arrow.
/// @param $thickness Thickness of the border.
/// @param $rotation Thickness of the border.
@mixin arrow($size, $thickness, $rotation: 45deg) {
  display: inline-block;
  content: '';
  transform: rotate($rotation);
  border-bottom: $thickness solid;
  border-right: $thickness solid;
  border-color: inherit;
  width: $size;
  height: $size;
  border-bottom-left-radius: $thickness;
  border-top-right-radius: $thickness;
}
