// Styles for OneTrust dynamic GDPR cookie policy
#optanon-cookie-policy {
  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy#cookie-policy-description,
  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-sdk-cookie-policy-group-desc,
  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-table-header,
  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy a,
  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy span {
    font-size: 1rem !important;
  }

  .ot-sdk-cookie-policy h3,
  .ot-sdk-cookie-policy h4 {
    font-weight: bold;
  }

  @include tablet() {
    .ot-sdk-cookie-policy > section {
      max-width: 100%;
      overflow-x: scroll;
    }
  }

  @include mobile() {
    .ot-sdk-cookie-policy.ot-sdk-container {
      padding: 0;
    }

    .ot-sdk-cookie-policy td:before {
      font-family: $font-snowflake-sans;
      font-weight: normal;
    }
  }
}

#onetrust-banner-sdk .onetrust-close-btn-ui.onetrust-lg:focus,
#onetrust-accept-btn-handler:focus,
#onetrust-banner-sdk #onetrust-accept-btn-handler:focus,
#onetrust-consent-sdk #onetrust-pc-btn-handler.cookie-setting-link:focus,
#onetrust-consent-sdk #onetrust-banner-sdk a:focus,
#onetrust-consent-sdk #onetrust-policy-text *:not(.onetrust-vendors-list-handler):focus {
  outline: 2px solid;
}

// Fix some quirks with the cookie banner on small desktops:
@media screen and (min-width: 897px) and (max-width: 1048px) {
  #onetrust-banner-sdk #onetrust-button-group {
    max-width: 200px !important;
  }
}

@media screen and (min-width: 796px) and (max-width: 1048px) {
  #onetrust-banner-sdk #onetrust-button-group {
    margin-right: 0 !important;
    margin-top: 20px !important;
    text-align: center;
  }
}
