// Simple text-only styles. Which apply to body and
// headline main sections. DO NOT ADD LAYOUT STYLES.
.entry-text {
  font-size: 1.125rem;

  a:not(.button) {
    color: inherit;
    border-bottom: 2px solid;
  }

  ol {
    padding-left: 1.2rem;
  }

  ul {
    list-style-type: none;
    padding: 1.5rem 0;
    margin: 0;

    li {
      position: relative;
      padding: 0 0 1rem 2.18rem;

      &:before {
        content: '';
        position: absolute;
        top: 0.5rem;
        left: 0;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background-color: $black;
        margin: 0 1.18rem 0 0;
        display: inline-block;
      }
    }
  }

  &.blue {
    ul li:before {
      background-color: $cyan-wt;
    }
  }

  p {
    line-height: 1.625rem;
    letter-spacing: 0.2px;
  }

  p:empty {
    display: none;
  }
}

@include mobile() {
  .entry-text {
    font-size: 1rem;
  }
}
