.video-section {
  position: relative;
  // JWTSOLWTCOM-320 - Prevent page jump when placeholders turn into videos
  min-height: 450px;

  .video-placeholder,
  .jwplayer {
    max-width: $page-max-width;
    margin: 0 auto;
  }

  .video-placeholder.transcoding > img {
    max-width: 100%;
  }

  .poster-container {
    position: relative;

    img {
      position: absolute;
      max-height: none;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .height-placeholder {
    max-width: $page-max-width;
    margin: 0 auto;

    > .buttons {
      height: 0;
      // Maintain 16/9 aspect ratio
      padding-top: 28.125%;
      padding-bottom: 28.125%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@include mobile() {
  .video-section {
    min-height: 200px;
  }
}

@include tablet() {
  .video-section {
    min-height: 425px;
  }
}
