// Grid Component
.grid-section {
  .grid-heading {
    margin: 0;
  }

  > .cards {
    .card {
      max-width: none !important;
    }

    // Desktops can have all 3 grid sizes:
    @include desktop {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: repeat(2, 1fr);

      &.columns-3 {
        grid-template-columns: repeat(3, 1fr);
      }

      &.columns-4 {
        grid-template-columns: repeat(4, 1fr);
      }

      // Make sure that the vertical cards do not have bottom padding in the last row.
      &.columns-3 .card.vertical:nth-of-type(-n + 3),
      &.columns-4 .card.vertical:nth-of-type(-n + 4) {
        padding-top: 0;
      }
    }

    // Tablets will always have 2 column grids:
    @include tablet {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: repeat(2, 1fr);
    }

    // Mobile gets single vertical flex column:
    @include mobile() {
      flex-direction: column;

      .card.vertical {
        padding: 0 0 1rem;

        .text-holder {
          padding: 0;
        }
      }
    }
  }
}
