@import "_common.scss";
@import 'layout';
@import 'header';
@import 'footer';
@import 'fonts';
@import 'modal';
@import 'gdpr';
@import 'stats';
@import 'buttons';
@import 'location';
@import 'prismjs';
@import 'onetrust';
@import 'body/download';
@import 'body/grid';
@import 'body/image';
@import 'body/image-with-text';
@import 'body/pullquote';
@import 'body/single-stat';
@import 'body/text';
@import 'body/video';
@import 'body/video-modal';
@import 'components/headline';
@import 'components/accordion';
@import 'components/card';
@import 'components/body-neo';
@import 'components/entry-text';
@import 'components/data-table';
@import 'components/people';
@import 'components/careers-table';
@import 'components/services-list';
@import 'components/prompt-modal';
@import 'components/social-connect-icons';
@import 'components/lang-selector';
@import 'components/legal';

body {
  margin: 0;
  box-sizing: border-box;
  font-family: $font-snowflake-sans;
  font-size: 16px;
  background-color: $page-background;
}

// Allow Czech translations to control the capitulation.
body.cs * {
  text-transform: inherit !important;
}

.text-medium {
  font-weight: normal;
}

.text-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

iframe {
  border: 0;
}

[v-cloak] {
  display: none !important;
}

input {
  display: block;
  box-sizing: border-box;
  padding: 1rem 0.5rem;
  border: 2px solid $grey-dark;
  text-align: center;
}

picture {
  font-size: 0;
}

address {
  font-style: normal;
}

// TODO: come back to this. used for hiding the overflow (main nav) while on the side.
// A better way to do it might be to animate the width of the modal as well.
// Revisit when we redo the navigation modal.
#site-container {
  position: relative;
  overflow: hidden;
}

.uppercase {
  text-transform: uppercase;
}

.invisible {
  visibility: hidden;
}

.mobile-only {
  display: none;
}

.desktop-only {
  display: block;
}

.dark {
  color: $white;

  svg {
    fill: $white;
  }

  a,
  button,
  &button {
    color: $white;

    &:-moz-focusring {
      outline-color: $white;
    }

    &:focus-visible {
      outline-color: $white;
    }
  }
}

.light {
  color: $black;

  svg {
    fill: $black;
  }

  a,
  button,
  &button {
    color: $black;
  }
}

.fill {
  object-fit: fill;
}

.contain {
  object-fit: contain;
}

.cover {
  object-fit: cover;
}

.none {
  object-fit: none;
}

.scale-down {
  object-fit: scale-down;
}

.accept-cookies-button:hover {
  background-color: inherit;
}

.prevent-scroll {
  overflow: hidden !important;
  height: 100%;
}

a {
  text-decoration: none;
}

// Accessibility Focus Indicator.
// NOTE: it's hard to get a good color that looks good on all buttons/links/backgrounds.
*:-moz-focusring {
  outline: 2px solid $grey-darkest;
}

*:focus-visible {
  outline: 2px solid;
  outline-color: $grey-darkest;
}

select:focus-visible {
  outline: 2px solid $grey-darkest !important;
}

.submenu-wrapper .submenu a:focus-visible {
  outline-color: $white;
}

.loading-spinner {
  border: 4px solid $white;
  border-top: 4px solid $warning;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

.section-key-right {
  width: 100%;
  height: 5rem;
  background: linear-gradient(to right, transparent 20% 85%, $grey-darkest 85%);
  margin-top: 2rem;
}

.section-key-left {
  width: 100%;
  height: 8rem;
  background: linear-gradient(to right, $grey-darkest 55%, transparent 55%);
  margin-bottom: 2rem;
}

.section-title {
  font-size: 1rem;
  line-height: 1rem;
  font-weight: bold;
  padding: 0 0 3.5rem 0;
  margin: 0;
  text-transform: uppercase;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.hidden {
  display: none !important;
}

@include tablet() {
  .content {
    width: 100%;
  }

  button:hover {
    background-color: transparent;
  }
}

@include mobile() {
  .mobile-only {
    display: block;
  }

  .desktop-only {
    display: none;
  }

  .content {
    width: 100%;
  }

  .section-title {
    padding: 0 0 2rem 0;
  }

  button:hover {
    background-color: transparent;
  }
}

svg {
  @each $name, $obj in $colors-names {
    &.#{$name} {
      color: map-get($obj, 'color');
      fill: map-get($obj, 'color');
    }

    &.#{$name}-dark {
      color: map-get($obj, 'color-dark');
      fill: map-get($obj, 'color-dark');
    }

    &.#{$name}-light {
      color: map-get($obj, 'color-light');
      fill: map-get($obj, 'color-light');
    }
  }
}
