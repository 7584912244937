@import "_common.scss";
@import 'tiny-slider/src/tiny-slider.scss';

.gallery {
  position: relative;
  outline: none;
  max-width: 100%;
  max-height: 100vh;

  .tns-controls,
  .controls {
    top: 0;
    // overwrite inline styles
    padding: 0 !important;
    position: absolute;
    z-index: 1;
    width: 100%;
    max-width: $page-max-width;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    outline: none;
    pointer-events: none;

    .arrow {
      pointer-events: all;
      // In order for the spacing to look the same we have to calculate the the section padding
      // (half of $column) minus width of the arrow button divided by 2.
      @include sliderArrow(-$column, #798082, 2.5rem);

      &:focus-visible {
        outline: $outline;
      }
    }
  }

  .slide {
    img {
      width: 100%;
      height: 100%;
      max-height: calc(100vh - 120px);
    }

    &.gif {
      text-align: center;
      video {
        max-width: 100%;
      }
    }
  }

  .tns-nav,
  .bullets {
    z-index: 1;
    bottom: calc(#{$column} / 2 * -1);
    outline: none;
    display: flex;
    width: 50%;
    @include centerAbsolute('horizontal');

    button,
    .bullet {
      flex: 1 1 auto;
      height: 0.31rem;
      margin: 0.5rem;
      padding: 0;
      cursor: pointer;
      outline: none;
      background-size: 200% 200%;
      background-position: -100% 0;
      transition: none;
      background-image: linear-gradient(to right, #798082 50%, #cfdce3 50%);
      border-radius: 2px;

      &.tns-nav-active {
        animation-name: loader-bar;
        animation-timing-function: linear;
      }

      &:not(.tns-nav-active) {
        background: #d3d3d3 !important;
      }
    }
  }

  @keyframes loader-bar {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -100% 0;
    }
  }

  @include mobile() {
    .tns-controls,
    .controls {
      display: none;
    }

    .bullets {
      display: none;
    }

    &.for-asset {
      padding-bottom: 2rem;

      .bullets {
        display: flex;
        width: 90vw;
        bottom: 0.3rem;
      }
    }

    .gallery .slide img {
      max-height: calc(100vh - 80px);
    }
  }
}

@include tablet() {
  // No Arrows on small screens
  .gallery {
    .controls {
      display: none;
    }
  }

  // Tablets without a slider, only get 2 stats.
  .stats-gallery .gallery .slider.disable-tablet {
    .slide:nth-child(n + 3) {
      display: none;
    }
  }
}

.gallery {
  // The stats slider doesn't need to be a slider on a desktop when less
  // than 4 items are present. In this case, we just do a flexed look.
  // Tablet/Mobile will have this class removed and become a true tiny-slider.
  .slider.disable-desktop,
  .slider.disable-tablet,
  .slider.disable-mobile {
    display: flex;
    justify-content: center;

    .slide {
      margin: 0 1rem;

      @include tablet() {
        margin: 0 0.2rem;
      }
    }
  }

  // This will hide slider controls until the slider js kicks in.
  // This way if a slider isn't used on a certain device size, we don't have controls.
  .slider ~ .controls,
  .slider ~ .bullets {
    display: none;
  }
}
