// Colors
$black: #000;
$white: #fff;
$grey-lighter: #f5f8f9;
$grey-faint: #e6e7e8;
$grey-light: #d8d8d8;
$grey-medium: #959698;
$grey-dark: #3a4f5f;
$grey-darkest: #2c2c2d;
$black-light: #323232;
$black-lighter: #4c4c4c;
$white-smoke: #f4f4f4;
$gray-vml: #707070;
$grey-vml: #BDBDBD;

// Branding updated to match parrot:
// WT Blue
$blue-wt: #7ea1e8;
$blue-wt-dark: #6b94e7;
$blue-wt-light: #90ade8;

// WT Violet
$violet: #5d3da4;
$violet-dark: #533693;
$violet-light: #7d63b6;

// WT Imperial
$imperial: #d198eb;
$imperial-dark: #bd7bdc;
$imperial-light: #deb7f0;

// WT Medium Ruby
$ruby-med: #964579;
$ruby-med-dark: #823968;
$ruby-med-light: #ab588e;

// WT Tango
$tango-pink: #f08d95;
$tango-pink-dark: #d3767d;
$tango-pink-light: #f6babf;

// WT Pink
$pink-wt: #ff9fcf;
$pink-wt-dark: #e590ba;
$pink-wt-light: #ffbbdd;

// WT Flame Red
$flame-red: #d85061;
$flame-red-dark: #cb3c4d;
$flame-red-light: #d85061;

// WT Orange
$orange-wt: #f78655;
$orange-wt-dark: #d87042;
$orange-wt-light: #f8b191;

// WT Yellow
$yellow-wt: #fdea7a;
$yellow-wt-dark: #e6d77e;
$yellow-wt-light: #fdf2af;

// WT Green Sheen
$green-sheen: #00c99f;
$green-sheen-dark: #13a898;
$green-sheen-light: #4cd9bb;

// WT Cyan
$cyan-wt: #9ffeff;
$cyan-wt-dark: #9eeaeb;
$cyan-wt-light: #c2feff;

// WT Dark Cerulean
$dark-cerulean: #345a7b;
$dark-cerulean-dark: #304c64;
$dark-cerulean-light: #4b779b;

$purple-vml: #cca4dc;

// Generate all the colors for the marks
$colors-names: (
  'blue': (
    'color': $blue-wt,
    'color-dark': $blue-wt-dark,
    'color-light': $blue-wt-light
  ),
  'violet': (
    'color': $violet,
    'color-dark': $violet-dark,
    'color-light': $violet-light
  ),
  'imperial': (
    'color': $imperial,
    'color-dark': $imperial-dark,
    'color-light': $imperial-light
  ),
  'ruby-med': (
    'color': $ruby-med,
    'color-dark': $ruby-med-dark,
    'color-light': $ruby-med-light
  ),
  'tango-pink': (
    'color': $tango-pink,
    'color-dark': $tango-pink-dark,
    'color-light': $tango-pink-light
  ),
  'pink': (
    'color': $pink-wt,
    'color-dark': $pink-wt-dark,
    'color-light': $pink-wt-light
  ),
  'flame-red': (
    'color': $flame-red,
    'color-dark': $flame-red-dark,
    'color-light': $flame-red-light
  ),
  'orange': (
    'color': $orange-wt,
    'color-dark': $orange-wt-dark,
    'color-light': $orange-wt-light
  ),
  'yellow': (
    'color': $yellow-wt,
    'color-dark': $yellow-wt-dark,
    'color-light': $yellow-wt-light
  ),
  'green-sheen': (
    'color': $green-sheen,
    'color-dark': $green-sheen-dark,
    'color-light': $green-sheen-light
  ),
  'cyan': (
    'color': $cyan-wt,
    'color-dark': $cyan-wt-dark,
    'color-light': $cyan-wt-light
  ),
  'dark-cerulean': (
    'color': $dark-cerulean,
    'color-dark': $dark-cerulean-dark,
    'color-light': $dark-cerulean-light
  ),
  /**
   Gray is the exception,
   the color of the mark is white and the background os gray
   */
    'gray':
    (
      'color': $white,
      'color-dark': #e5e5e3,
      'color-light': #eeeeee
    ),
  'black': (
    'color': $black,
    'color-dark': $black,
    'color-light': $black-light
  )
);

$error: #e3170d;
$warning: $orange-wt;

// TODO: add the warm overlay color, if we have it.
$overlay-warm: #005377;
$overlay-cool: #005377;

$page-background: #fff;

// jwt intelligence colors
$jwt-intelligence-blue: #41b6e6;
$jwt-intelligence-green: #0eb491;
$jwt-intelligence-pink: #ff596d;
$ff-blue: #45cad8;

// fonts
$font-snowflake-sans: 'SnowflakeSans', Arial, sans-serif;

// sizes
$short: 700px;
$tablet: 600px;
$desktop: 980px;
$widescreen: 1180px;
$extrawide: 1280px;
$stat-width: 305px;
$stat-height: 390px;
$main-menu: 1139px;
$main-menu-link: 1355px;

// z-index
$z-headline: -2;
$z-low: $z-headline + 1;
$z-normal: 0;
$z-above: $z-normal + 1;
$z-dropdown: $z-above + 1;
$z-nav: $z-dropdown + 1;

$z-modal: 100;

// Animation variables
$anim-dur-headline: 1000ms;
$anim-func-headline: ease-in-out;
$anim-dur-nav: 300ms;
$anim-func-nav: ease-in-out;

// The design is divided into 18 columns
$column: calc(100vw / 18);
$section-space: 5rem;
$section-space-mobile: 3.75rem;
$small-screen-content-margin: 2rem;

// layout vars
$content-width: 960px;
$page-max-width: 1440px;
$nav-height: 4.75rem;
$iframe-vertical-space: 2rem;

// accessibility
$outline: 2px solid $blue-wt;
