.social-connect-icons {
  color: $grey-darkest;

  .logos {
    display: flex;

    a {
      margin-right: 0.6rem;

      &:focus {
        outline: 2px solid $white;
      }
    }

    .social-icon {
      fill: $grey-darkest;
      width: 30px;
      height: 30px;
      display: block;

      &.vimeo {
        width: 35px;
      }
    }
  }
}

.light .social-connect-icons {
  color: $black;

  .social-icon {
    fill: $black;
  }
}

@include mobile() {
  .social-connect-icons {
    .logos {
      a {
        margin-right: 1.3rem;
      }
    }
  }
}
