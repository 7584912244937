.stats-gallery {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
}

// IE11 Flexbox bug fix.
.gallery.for-stat {
  margin-left: auto;
  margin-right: auto;
}

.stat-graphic {
  background-color: $black;
  color: $white;
  position: relative;
  width: $stat-width;
  height: $stat-height;
  padding: 1.25rem;
  box-sizing: border-box;
  fill: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  h5 {
    margin: 0 0 1rem 0;
    font-size: 1rem;
    line-height: 1.375rem;
    letter-spacing: 3px;
    text-transform: uppercase;
    position: relative;
    z-index: 1;
  }

  .stat-graphic-content {
    z-index: 1;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 19.5rem;

    header {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      font-family: $font-snowflake-sans;
      font-weight: normal;
      margin: 0 0 1.45rem 0;
      font-size: 2.25rem;
      height: 60%;

      &.long-title {
        font-size: 1.7rem;
      }
    }

    .stat-copy {
      height: 40%;
      line-height: 1.25rem;
    }
  }
}

.stats-gallery-slider .tns-ovh {
  max-width: $page-max-width;
  margin: 0 auto;
}

@include mobile() {
  .stats-gallery {
    margin: 0;
  }

  .stat-graphic {
    margin: 3rem 0;
  }
}
