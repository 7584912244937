// TODO: this all needs to be consolidated with related-section and similar.
// No need to have multiple class modifiers.
.card-section {
  @include mobile() {
    padding-left: 0;
    padding-right: 0;

    > .section-title {
      padding: 0 0 2rem $column;
    }
  }
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &.is-loading {
    position: relative;
  }

  .loading-mask {
    position: absolute;
    z-index: $z-above;
    background-color: hsla(0, 0%, 100%, 0.7);
    margin: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: opacity 0.3s;

    .loading-spinner {
      position: absolute;
      width: 40px;
      height: 40px;
      top: 2rem;
      left: calc(50% - 20px);
    }
  }

  .no-results {
    padding: 2rem $column 6rem $column;
    flex-basis: 100%;
    text-align: center;
  }

  .card {
    display: flex;
    width: 100%;

    // Common card styles
    .text-holder {
      position: relative;
      box-sizing: border-box;
    }

    .text {
      position: relative;

      .card-link {
        font-weight: bold;
        border-bottom: 1px solid;
        padding-bottom: 3px;
        font-size: 1.125rem;
        text-transform: uppercase;
        margin: 1em 0;

        .more-arrow-icon {
          fill: $black;
          stroke: none;
          width: 15px;
          height: 14px;
          margin: 0 0 0 0.7rem;
        }
      }
    }

    .title {
      font-weight: bold;
      max-width: 100%;
      font-size: 1.95rem;
    }

    .subtitle {
      font-weight: normal;
      font-size: 1.1rem;
      line-height: 1.6rem;
      margin: 0;
      max-width: 100%;
    }

    .client {
      font-size: 1.4rem;
      margin: 0.4rem 0 0 0;
      max-width: 100%;
    }

    .type {
      font-size: 1.125rem;
      margin: 1em 0;
      text-transform: uppercase;
    }

    .client-award {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 2.5rem;
      width: 100%;

      svg {
        stroke: none;
      }
    }

    .client {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      text-transform: uppercase;
      font-size: 1.4rem;
      margin: 0.4rem 0 0 0;
      max-width: 70%;
    }

    .has-awards {
      position: relative;
      width: 2.2rem;
      min-width: 2.2rem;
      height: 2.2em;

      svg {
        width: 100%;
        height: 100%;
      }

      &:before {
        position: absolute;
        left: calc(-100% - 5rem);
        @include centerAbsolute('vertical');
        display: none;
        white-space: nowrap;
        text-transform: none;
        font-size: 0.8rem;
        border-radius: 3px;
        z-index: $z-above;
        content: attr(data-title);
      }

      &:hover:before {
        display: block;
      }
    }

    &.dark {
      hr {
        border-top: 1px solid $white;
      }

      a {
        color: $white;

        .more-arrow-icon {
          fill: $white;
          stroke: none;
        }
      }
    }

    &.light {
      hr {
        border-top: 1px solid $black;
      }

      a {
        color: $black;
      }

      .has-awards {
        fill: $black;
        stroke: none;
      }
    }

    hr {
      border: 0;
      margin: 1rem 0;
      width: 100%;
    }

    &.horizontal {
      .snowflake-wrapper {
        position: absolute;
        height: 100%;
        padding: 5% 0;
        box-sizing: border-box;
        aspect-ratio: 0.5;
        left: 0;
        top: 0;
        z-index: $z-above;

        .snowflake {
          height: 100%;
          aspect-ratio: 1;
          transform: translateX(-60%);
        }
      }

      &:nth-child(2n) {
        .snowflake-wrapper {
          position: absolute;
          left: 100%;

          .snowflake {
            transform: translateX(-40%);
          }
        }
      }

      .text-holder {
        flex: 1 0 50%;
        display: flex;
        align-items: center;
        position: relative;
      }

      .text {
        margin: 0;
        padding: 0 $column;
        box-sizing: border-box;
        overflow-wrap: break-word;
        position: relative;
        z-index: $z-above;
        width: 100%;
        max-width: calc((#{$page-max-width} / 2));
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .title {
          margin: 0 0 1rem 0;
          @include titleSizes(2.6rem, 2.5rem, 0.3);
        }
      }

      .text-holder + a,
      .text-holder + picture {
        flex: 1 1 50%;
      }

      img {
        width: 100%;
        height: 100%;
        display: block;
      }

      &:nth-child(even) {
        img {
          order: 0;
        }

        .text-holder {
          order: 1;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
      }
    }

    // Horizontal card styles
    &.horizontal {
      height: 84vh;
      position: relative;
      overflow: hidden;

      .text-holder {
        justify-content: flex-end;
        overflow: hidden;
      }
    }

    // Vertical card styles
    &.vertical {
      flex-direction: column;
      max-width: 48%;
      padding-top: 3rem;

      .client-award {
        margin: 10px 0 1rem 0;

        .has-awards svg {
          fill: $black;
          stroke: none;
        }
      }

      img {
        width: 100%;
        display: block;
      }

      .text-holder {
        flex: 1 1 auto;
        background-color: transparent !important;
        color: $black !important;
        margin: 0;

        .text {
          min-height: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding-left: 1rem;
          box-sizing: border-box;

          .client-award .client {
            font-size: 1.06rem;
          }

          .subtitle:first-child {
            // Align card content that was manually created
            flex: 1;
          }
        }
      }

      .title {
        margin: 1rem 0;

        // Align card content
        + *:not(hr) {
          flex: 1;
        }

        a {
          color: $black;
          border: none;
          line-height: normal;
        }
      }

      hr {
        display: none;
      }

      .subtitle {
        font-size: 1.1rem;
      }

      .card-link span {
        &:before {
          content: '';
          position: absolute;
          height: 0.5rem;
          width: 0.5rem;
          margin: 0.4rem 0 0 0.3rem;
          border-bottom: 2px solid $black;
          border-right: 2px solid $black;
          transform: rotate(-45deg);
        }

        &.button-content:before {
          right: -0.75rem;
        }
      }
    }

    &.video {
      > span {
        position: relative;
        display: block;
      }

      .buttons {
        @include centerAbsolute('all');
      }
    }
  }

  @include widescreen() {
    .card.horizontal .title {
      @include titleSizes(3rem, 2.9rem, 0.5);
    }
  }

  // Reset the row spacing for the first row.
  @include tabletAndUp() {
    .card.vertical:nth-of-type(-n + 2) {
      padding-top: 0;
    }
  }

  @include tablet() {
    .card {
      &.horizontal {
        height: 45vh;
      }

      &.vertical {
        display: block;

        .text-holder {
          min-height: 40%;

          .text .title {
            font-size: 1.8rem;
            line-height: 1.8rem;
          }
        }
      }
    }
  }

  @include mobile() {
    &.vertical-cards {
      padding-left: 0;
      padding-right: 0;
    }

    .card {
      &.horizontal {
        flex-direction: column;
        position: relative;
        min-height: 100vh;
        height: 100%;

        .snowflake-wrapper {
          display: none;
        }

        picture {
          order: 1;

          img {
            width: 100%;
            height: 100vw;
          }
        }

        .text-holder {
          order: 2;

          .text {
            margin: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
          }

          .subtitle {
            font-size: 1.1rem;
            line-height: 1.4rem;
          }

          .card-link {
            margin-bottom: 0;
          }
        }

        &:nth-child(even) {
          picture {
            order: 1;
          }

          .text-holder {
            order: 2;
          }
        }

        .text-holder + a,
        .text-holder + picture {
          flex: 0 1 50%;
        }
      }

      &.vertical {
        width: 100%;
        height: auto;
        max-width: 100%;

        // Reset the vertical spacing for the first child.
        &:first-child {
          padding-top: 0;
        }

        picture {
          img {
            width: 100%;
            height: 100vw;
          }
        }

        &.vertical {
          picture {
            img {
              width: 100%;
              height: auto;
            }
          }
        }

        .text-holder {
          height: auto;
          min-height: 0;
          padding: 1rem $column;

          .text {
            margin: 0;
            padding: 0;

            .title {
              font-size: 1.9rem;
              line-height: 1.9rem;
            }

            .subtitle {
              font-size: 1.1rem;
              line-height: 1.4rem;
            }

            .client-award {
              margin: 10px 0 0;
            }
          }
        }
      }

      .text {
        form {
          .card-link {
            color: $black;
          }
        }
      }
    }
  }
}
