.modal {
  position: fixed;
  display: none;
  background: #fff;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: $z-modal;

  // We only want the transparency on full-size modals
  &.full-size {
    background: rgba($page-background, 0.95);
  }

  &.signup {
    z-index: $z-modal + 1;
    width: 400px;
    @include centerAbsolute('all');
    position: fixed;
    box-shadow: 0 0 10px $grey-dark;
    height: auto;
    padding: 1rem;
    overflow: hidden;
    color: $grey-dark;

    a {
      color: $grey-dark;
      border-bottom: 2px solid #000;
    }

    .close {
      top: 0.25rem;
      right: 1rem;
      font-size: 0.75rem;
      outline: none;
    }

    .error {
      color: $error;
      font-weight: 700;
      margin-top: 0;
      display: none;
    }

    .errorShow {
      display: block;
    }
  }

  &.active {
    display: block;
  }

  .close {
    z-index: $z-modal + 1;
    padding: 5px;
    background-color: transparent;
    border: 0;
    appearance: none;
    cursor: pointer;
    position: absolute;
    display: block;
    font-size: 0.75rem;
    height: 3em;
    width: 3em;
    top: 1em;
    right: 2em;
    margin: 0.75rem 0;

    &:hover:before,
    &:hover:after {
      background: $black;
    }

    &:before,
    &:after {
      content: '';
      display: block;
      transform: rotate(45deg);
      width: 3em;
      height: 0.4em;
      transform-origin: center;
      background: $grey-dark;
      border-radius: 2px;
      box-sizing: border-box;
      @include centerAbsolute('all', 45deg);
    }

    &:after {
      @include centerAbsolute('all', -45deg);
    }
  }

  .modal-item {
    position: relative;
    margin-top: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &.twitter,
    &.facebook,
    &.instagram {
      height: 700px;
    }

    iframe {
      width: 560px;
      height: 100%;
      min-height: 600px;
    }

    &.youtube,
    &.vimeo {
      // maintain the 16:9 aspect ratio for video and.
      padding-bottom: 56.25%;

      iframe {
        top: 6em;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }

    > h2,
    > h3 {
      margin: 0 0 1rem 0;
    }

    img,
    video {
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: 100%;
    }
  }

  @include mobile() {
    .modal-item {
      &.twitter,
      &.facebook,
      &.instagram {
        height: inherit;
      }
    }

    &.active .close {
      top: 3em;
    }

    .content {
      padding: 0 2vw 100px 2vw;
      box-sizing: border-box;
      margin-top: 3.75rem;
    }

    iframe {
      max-width: 98vw;
    }

    &.signup {
      width: 90vw;
    }
  }
}
