.gdpr-placeholder {
  color: #fff;
  min-height: calc(500px * (9 / 16));
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  flex-direction: column;
  background: rgba(33, 35, 34, 0.7);
  position: relative;

  // Used to avoid flicker of placeholder while we load JS.
  &.transparent {
    visibility: hidden;
  }

  &:not(.dnt) {
    .dnt-text {
      display: none;
    }
  }

  &.dnt {
    .cookie-text,
    .optanon-toggle-display {
      display: none;
    }
  }

  .optanon-toggle-display {
    border-bottom: 2px solid $white;
  }

  .social-logo {
    max-width: 130px !important;
    height: auto !important;
    position: absolute;
    bottom: 18px;
    right: 18px;
  }

  p {
    width: 60%;
    background: url(/img/gdpr-cookies.svg) top right no-repeat;
    background-size: 63px 56px;
    min-height: 56px;
    padding-right: 73px;

    a {
      cursor: pointer;
    }
  }
}

@include mobile {
  .gdpr-placeholder {
    p {
      padding-bottom: 50px;
    }
  }
}

@include tablet {
  .gdpr-placeholder {
    min-height: calc(700px * (9 / 16));
    font-size: 18px;

    p {
      width: 70%;
      background-size: 103px 96px;
      min-height: 96px;
      padding-right: 125px;

      a {
        font-size: 16px;
        white-space: nowrap;
      }
    }

    .placeholder-type {
      bottom: 30px;
      right: 30px;
      background-size: 75%;
    }
  }
}

@include desktop {
  .gdpr-placeholder {
    &:not(.spotify) {
      min-height: calc(900px * (9 / 16));
    }

    p {
      width: 75%;

      a {
        white-space: nowrap;
      }
    }

    .placeholder-type {
      background-size: 100%;
    }

    &.dnt {
      width: 100%;
      p {
        width: 55%;
      }
    }
  }
}

// GDPR cookie banner styles.
#onetrust-consent-sdk {
  #onetrust-policy-text {
    font-size: inherit !important;
  }

  #onetrust-button-group button {
    text-transform: uppercase !important;
    font-family: $font-snowflake-sans !important;
    font-weight: bold !important;

    &.cookie-setting-link {
      font-family: $font-snowflake-sans !important;
      border-bottom: 2px solid $white;
      text-decoration: none;
      padding-bottom: 2px;

      &:focus {
        opacity: 1;
      }
    }

    &.cookie-setting-link:hover {
      opacity: 1 !important;
    }
  }

  #onetrust-banner-sdk .ot-close-icon {
    background-image: none !important;
    background-color: transparent;
    appearance: none;

    &:focus {
      outline: 2px solid $white;
      opacity: 1;
    }

    &:before,
    &:after {
      content: '';
      display: block;
      transform: rotate(45deg);
      width: 2em;
      height: 0.3em;
      transform-origin: center;
      background: $white;
      border-radius: 2px;
      box-sizing: border-box;
      @include centerAbsolute('all', 45deg);
    }

    &:after {
      @include centerAbsolute('all', -45deg);
    }
  }

  #onetrust-button-group {
    text-align: center;

    .cookie-setting-link {
      width: auto !important;
    }
  }

  @include mobile {
    #onetrust-policy,
    #onetrust-policy-text {
      margin-bottom: 0;
    }
  }

  @include desktop {
    #onetrust-banner-sdk #onetrust-button-group {
      margin-top: 0 !important;
    }
  }
}
