#site-footer {
  position: relative;
  background: $black;
  padding: 2rem 0;
  overflow: hidden;
  color: $white;

  .section-title {
    padding-bottom: 1rem;
    letter-spacing: 3.2px;
  }

  .snowflake-wrapper {
    position: relative;
    z-index: 0;
    padding-top: 7rem;
    opacity: 0.25;
    width: 100%;

    .snowflake {
      position: absolute;
      width: 100%;
      aspect-ratio: 1;
    }
  }

  .footer-section {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0 $column;
  }

  .footer-middle {
    display: flex;
    color: $white;
    padding-top: 3rem;

    .footer-contact {
      width: 75%;

      .social-connect-icons {
        padding: 0;
        border: 0;
        margin: 0;
        color: $white;
        display: flex;
        flex-direction: column;
        align-items: center;

        .social-icon {
          fill: $white;
        }
      }
    }

    .footer-links {
      width: 100%;

      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        &:first-of-type {
          text-transform: uppercase;
        }

        &.nav-main {
          display: flex;
          letter-spacing: 3.2px;
          padding-bottom: 1rem;

          li {
            font-size: 1rem;
            font-weight: bold;
            margin: 0 3.3rem 1rem 0;
          }
        }

        &.nav-secondary {
          li {
            font-size: 1rem;
            margin: 1.2rem 0.8rem 0.8rem 0;

            button.link.optanon-toggle-display {
              font-weight: normal;
              text-transform: none;
            }
          }
        }

        li {
          a,
          button {
            color: $white;

            &:hover {
              text-decoration: underline;
            }
          }

          &.bold-link {
            a {
              border: none;
              clip-path: inset(0 3.2px 0 0);
              display: block;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }

  .footer-bottom {
    display: flex;
    margin-top: 2rem;

    h3 {
      font-weight: normal;
      font-size: 1rem;
      margin: 0;
      color: $white;

      a {
        justify-content: flex-end;
        flex-flow: wrap;
        list-style-type: none;
        margin: 0;
        padding: 0;
        color: $white;
        text-decoration: none;
        border-bottom: 2px solid $white;
        display: inline-block;
      }
    }
  }

  *:-moz-focusring {
    outline: 2px solid $white;
  }

  *:focus-visible {
    outline: 2px solid $white;
  }
}

@include desktopOnly() {
  #site-footer {
    .footer-section .footer-bottom {
      h3 {
        width: 45%;
      }

      ul {
        width: 75%;
      }
    }
  }
}

@include tablet() {
  #site-footer {
    padding: 2rem 0 0;

    .footer-section {
      justify-content: flex-end;
      padding: 0 $column $column;

      .footer-bottom {
        margin: $section-space 0 0;

        h3 {
          width: 65%;

          a {
            margin: 0.5rem 0 0 0;
          }
        }

        ul {
          width: 55%;
          justify-content: flex-start;
        }
      }
    }

    .footer-middle {
      .footer-contact {
        width: 45%;
      }

      .footer-links {
        width: 100%;

        ul.nav-main {
          flex-direction: column;

          li {
            margin: 0 0 1.4rem 0;
          }
        }
      }
    }
  }
}

@include mobile() {
  #site-footer {
    padding: calc(#{$section-space} / 2) 1.5rem 0;
    height: 100%;

    .snowflake-wrapper {
      padding-top: 3rem;
    }

    .footer-mark {
      transform: rotate(-8deg) translate(3%, -25%);
      width: 225%;
      height: 225%;
    }

    .footer-section {
      justify-content: space-evenly;
      padding: 0;
    }

    .footer-top {
      padding: 0 0 2.5rem 0;
      margin: 0 0 2.5rem 0;
    }

    .footer-middle {
      flex-direction: column;
      padding-top: 0;

      .footer-contact {
        width: 100%;
        margin: 3rem 0;

        button {
          margin: 0 0 3.5rem 0;
        }

        .social-connect-icons {
          float: none;

          p {
            letter-spacing: 3px;
          }
        }
      }

      .footer-links {
        width: 100%;

        .nav-main {
          flex-direction: column;

          li {
            margin: 1.4rem 0;
          }
        }
      }
    }

    .footer-bottom {
      flex-direction: column;
      margin-top: 0;

      h3 {
        width: 100%;
        margin: 0 0 2.5rem 0;

        a {
          display: inline-block;
          margin: 0.4rem 0 0 0;
        }
      }
    }
  }
}
