/*
 * Content wrappers and horizontal alignment.
 * cnt-fc: full ($page-max-width) center
 * cnt-ml: medium ($content-width) left
 */

// Full width container.
.cnt {
  margin-left: auto;
  margin-right: auto;
  max-width: none;
  background-clip: content-box;
}

// Main container limited to 1440px
.cnt-fc {
  margin-left: auto;
  margin-right: auto;
  max-width: $page-max-width;
  box-sizing: border-box;
  padding-left: $column;
  padding-right: $column;
}

// Smaller left aligned content.
.cnt-ml {
  margin-left: calc((100% - #{$page-max-width}) / 2);
  max-width: $content-width;
  box-sizing: border-box;
  padding-left: $column;
  padding-right: $column;
}

@media screen and (max-width: $page-max-width) {
  .cnt-ml {
    margin-left: 0;
  }
}

/*
 * Vertical spacing modifiers.
 * vs-f: full ($column) center
 * vs-m: medium ($column*0.75) left
 * vs-s: small ($column*.5) left
 */
.vs-f {
  padding-top: $column;
  padding-bottom: $column;
}

.vs-m {
  padding-top: $column * 0.75;
  padding-bottom: $column * 0.75;
}

.vs-s {
  padding-top: $column * 0.5;
  padding-bottom: $column * 0.5;
}
