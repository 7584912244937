@font-face {
  font-family: 'SnowflakeSans';
  src: url('https://assets.vml.com/fonts/snowflake/SnowflakeSans-Book.woff2') format('woff2'),
    url('https://assets.vml.com/fonts/snowflake/SnowflakeSans-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SnowflakeSans';
  src: url('https://assets.vml.com/fonts/snowflake/SnowflakeSans-Bold.woff2') format('woff2'),
    url('https://assets.vml.com/fonts/snowflake/SnowflakeSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SnowflakeSans';
  src: url('https://assets.vml.com/fonts/snowflake/SnowflakeSans-Black.woff2') format('woff2'),
    url('https://assets.vml.com/fonts/snowflake/SnowflakeSans-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SnowflakeSans';
  src: url('https://assets.vml.com/fonts/snowflake/SnowflakeSans-Italic.woff2') format('woff2'),
    url('https://assets.vml.com/fonts/snowflake/SnowflakeSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SnowflakeSans inline';
  src: url('https://assets.vml.com/fonts/snowflake/SnowflakeInline-Inlinex1.woff2') format('woff2'),
    url('https://assets.vml.com/fonts/snowflake/SnowflakeInline-Inlinex1.woff') format('woff');
  font-weight: normal;
  font-display: swap;
}
