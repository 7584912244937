.pullquote-section {
  position: relative;
  width: fit-content;
  margin: 0 auto;
  padding: $column $column calc(#{$column} / 4 * 3);

  > .background {
    position: absolute;
    width: 100vw;
    height: 100%;
    z-index: $z-low;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &.spacing-full {
    padding: calc(#{$column} / 4 * 3) $column;
  }

  .quote-text {
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    .text {
      max-width: $content-width;
    }

    .spacer {
      margin: 0;
      width: 1.5rem;
      opacity: 0.7;
    }

    &.quote-no-author {
      padding: 0;
    }
  }

  .pullquote {
    position: relative;
    color: inherit;

    &:before {
      content: open-quote;
      position: absolute;
      top: -4rem;
      left: -6rem;
    }

    &:after {
      content: close-quote;
      position: absolute;
      bottom: -9rem;
      right: -6rem;
    }

    &:before,
    &:after {
      font-weight: bold;
      display: inline-block;
      color: inherit;
      font-size: 12rem;
    }
  }

  .text {
    position: relative;
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: normal;
    margin: 0 0 1rem 0;
    max-width: none;
    color: $black;
  }

  .author,
  .role {
    font-size: 1.12rem;
    display: inline-block;
    color: $black;
  }

  .author {
    margin-bottom: 0;
  }

  .role {
    margin-top: 0;
  }

  .quote-text {
    &.dark {
      .text,
      .role,
      .author {
        color: $white;
      }

      .spacer {
        border-color: $white !important;
        background: $white;
      }
    }

    &.light {
      .spacer {
        border-color: $black !important;
        background: $black;
      }
    }
  }

  @include desktopOnly() {
    .quote-text {
      .text,
      .author,
      .role {
        max-width: 720px;
      }
    }
  }

  @include tablet() {
    padding: $column * 2 $column 0;

    .pullquote {
      .text {
        padding: 1rem 0 4rem;
      }

      &:before {
        left: -1rem;
        top: -7rem;
      }

      &:after {
        right: 0;
        bottom: -6.5rem;
      }
    }

    hr.spacer {
      max-width: 33%;
      margin: 0 auto 0 0;
    }

    .quote-text {
      &.quote-no-role {
        padding: 0 0 1rem 0;
      }
    }
  }

  @include mobile() {
    margin: 0 auto;

    &.spacing-full {
      padding: $column 0;
    }

    .pullquote {
      &:before {
        top: -5rem;
        left: 0;
      }

      &:after {
        right: 0;
      }
    }

    .quote-text {
      max-width: 325px;
      margin: 2rem auto;

      &.quote-no-author {
        padding: 0;
      }

      &.quote-no-role {
        padding: 0;
      }

      .pullquote {
        &:after {
          bottom: -8rem;
        }
      }

      .text {
        font-size: 1.6rem;
        line-height: 2.1rem;
        margin: 0 auto 2.5rem;
        padding: 3rem 0;
      }

      .spacer {
        margin: 0 0 0.5rem;
      }
    }

    .author,
    .role {
      font-size: 1rem;
      line-height: 1.4rem;
      display: inline;
    }
  }
}
