section.body-neo {
  width: 100%;

  & > section:last-child {
    margin-bottom: calc(#{$column} / 2);
  }

  .spacing-full {
    padding: calc(#{$column} / 4 * 3) $column;
  }

  .heading-section {
    position: relative;
    overflow: hidden;
    max-width: $page-max-width;
    margin: 0 auto;
  }

  .link-button-section {
    .title {
      font-size: 2.25rem;
      letter-spacing: 0.5px;
      margin: 0 0 1.6rem 0;
      font-weight: normal;
    }

    .text-holder .text {
      font-size: 1.12rem;
      line-height: 1.625rem;
    }

    .link-btn {
      display: flex;
      justify-content: flex-start;
      padding: 0.9rem 0;
      margin: 0.75rem 0;
      text-transform: capitalize;
    }
  }

  .text-holder {
    position: relative;
    z-index: $z-above;

    .title,
    .text,
    p,
    ol,
    ul {
      a {
        border-bottom: 2px solid;
      }
    }
  }

  .heading-section {
    .title {
      font-size: 2.5rem;
      line-height: 3rem;
      color: $black;
      margin: 0;
      font-weight: normal;
    }
  }

  .stat-section {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
  }

  .stats-gallery-slider .tns-ovh {
    max-width: $page-max-width;
    margin: 0 auto;
  }

  .video-section {
    position: relative;
    max-width: $page-max-width;
    margin: 0 auto;

    .poster-container {
      position: relative;

      img {
        position: absolute;
        max-height: none;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .height-placeholder {
      max-width: $page-max-width;
      margin: 0 auto;

      > .buttons {
        height: 0;
        // Maintain 16/9 aspect ratio
        padding-top: 28.125%;
        padding-bottom: 28.125%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .copyright-section {
    .text {
      font-size: 0.75rem;
    }
  }

  .image-section {
    max-width: $page-max-width;
    margin: 0 auto;
  }

  iframe.spotify {
    width: 100%;

    &.song {
      max-width: 300px;
      height: 300px;
    }
  }

  .gdpr-placeholder {
    margin-bottom: calc(#{$column} / 2);

    &.hubspot-form {
      .social-logo {
        max-height: 56px;
        max-width: 56px !important;
      }
    }
  }

  iframe {
    &.vimeo,
    &.youtube,
    &.slideshare,
    &.facebook {
      width: 100%;
      min-height: 550px;
      margin: $iframe-vertical-space auto;
    }
  }

  @include mobileAndTablet {
    .code-block-section.spacing-text {
      padding: calc(#{$column} / 2) $column;
    }
  }

  @include tablet() {
    .stat-section {
      padding: ($column * 0.75) $column;

      .single-data-side h2,
      .single-data-side .text {
        margin-right: 1rem;
      }
    }
  }

  @include mobile() {
    p {
      overflow-wrap: anywhere;
    }

    h3 {
      font-size: 1.7rem;
    }

    iframe {
      &.vimeo,
      &.youtube,
      &.slideshare,
      &.facebook {
        min-height: auto;
      }
    }

    .text-section {
      &.grid-heading {
        padding: 0;
      }
    }
  }
}
