.image-section {
  font-size: 0;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;

  img {
    width: 100%;
    display: block;
    object-fit: cover;

    // NOTE: The label for this has changed to Fit but the DB value stayed original.
    &.original {
      margin: 0 auto;
      max-height: 80vh;
      max-width: 100%;
      width: auto;
      object-fit: initial;
    }
  }

  figure {
    margin: 0;

    figcaption {
      font-size: 0.75rem;
      width: 100%;
      text-align: center;
      margin-top: 0.25rem;
    }

    // Center videos from gif files.
    &.gif {
      text-align: center;
      video {
        max-width: 100%;
      }
    }
  }
}

// Make images/videos on small wide desktops fit.
@include nicoleDesktop() {
  .image-section {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
  }
}
