// Careers Listing Table
.careers-table {
  .jobs {
    width: 100%;
    box-sizing: border-box;
    border-spacing: 0;

    .jobs-header,
    .job-listing {
      width: 100%;
      margin-bottom: 10px;
    }

    // spacer for the content
    .jobs-header tr:nth-child(2) td {
      padding-bottom: 22px;
    }

    .job {
      font-size: 1rem;
      line-height: 1.6rem;
      padding: 0.4rem 1rem 1rem 0;

      &.head {
        padding: 0 0 0.2rem 0;
        font-size: 1.1rem;
        text-decoration: uppercase;
        border-bottom: 2px solid $black;
        text-align: left;
      }

      &-title {
        a {
          color: $black;
          border-bottom: 2px solid $black;
        }

        .job-subtitle {
          margin-top: 5px;
          font-size: 0.85rem;
          color: $grey-dark;
        }
      }

      &-share {
        text-align: right;
        padding: 1.4rem 0;

        a {
          color: $blue-wt;
          font-weight: bold;
          text-transform: uppercase;
        }

        .more-arrow {
          width: 0.56rem;
          height: 0.87rem;
          fill: $blue-wt;
          margin: 0 0 0 0.63rem;
        }
      }

      &-post {
        min-width: 125px;
      }

      &.job-location a {
        color: $black;
        border-bottom: 2px solid $black;
      }
    }
  }

  @include tablet() {
    .jobs {
      .job {
        font-size: 0.87rem;
        line-height: 1.4rem;

        &.head {
          padding: 0.4rem 0 1rem 0;
        }

        &-share {
          padding: 1rem 0;
        }
      }
    }
  }

  @include mobile() {
    .current-job-openings {
      .title {
        padding: 0.75rem 0 0.43rem 0;
      }
    }

    .jobs {
      display: block;
      margin-bottom: 2.5rem;

      .jobs-header {
        display: none;
      }

      .job-listing {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
      }

      .job {
        margin: 2px 0;
        padding: 0;

        &.job-title a {
          float: left;
          clear: both;
          margin-bottom: 10px;
        }

        p {
          margin: 0;
          font-size: 0.85rem;
          line-height: 1.2rem;
          color: #3a4f5f;
          float: left;
          clear: right;
        }

        &.job-title svg {
          margin-top: 4px;
        }

        &.job-dept svg {
          margin-top: -1.5px;
        }

        &.job-expertise svg {
          margin-top: -1.5px;
        }

        &.job-location svg {
          margin-top: -1px;
        }

        svg {
          width: 18px;
          height: 19px;
          margin-right: 10px;
          float: left;
          clear: left;
        }
      }
    }
  }
}
