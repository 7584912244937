.stat-section {
  .single-stat {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .single-data-side {
      max-width: calc(100% - #{$stat-width});

      h2 {
        margin-top: 0;
      }

      .text {
        margin: 0 auto 0 0;
        font-size: 1.12rem;
        line-height: 1.625rem;
        padding-right: 1rem;

        p:first-child {
          margin-top: 0;
        }
      }
    }
  }

  @include tablet() {
    .single-data-side {
      h2,
      .text {
        max-width: 100%;

        p:first-child {
          margin-top: 0;
        }
      }
    }
  }

  @include mobile() {
    .single-stat {
      flex-direction: column;
      align-items: center;
      max-width: 100%;

      .single-data-side {
        max-width: 100%;

        h2,
        .text {
          padding: 0 $column;
        }

        .text {
          margin: 0 auto;
          font-size: 1rem;

          p:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
}
