.lang-slideout {
  position: fixed;
  top: 160px;
  right: 0;
  z-index: $z-dropdown;
  width: 35px;
  transition: right 0.2s;
  font-size: 0.75rem;
  text-transform: uppercase;
  color: $white;

  &.expand {
    right: 125px;

    .lang-section {
      left: 0;
    }
  }

  .lang-tab {
    position: relative;
    top: 15px;
    right: 4px;
    padding: 1rem;
    font-size: 0.75rem;
    font-family: $font-snowflake-sans;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    background: $white 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px rgba($black, 0.29);
    border: 1px solid $grey-vml;
    color: $black;
    padding: 12px 16px 12px 12px;
    display: none;
    color: $black;

    &.active {
      display: block;
    }

    &.long {
      right: 30px;
      white-space: nowrap;
    }
  }

  .lang-section {
    position: absolute;
    top: 5px;
    left: 50px;
    min-width: 150px;
    padding: 0 25px 0 0;
    background: $white 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px rgba($black, 0.29);
    border: 1px solid $grey-vml;

    .lang-options {
      position: inherit;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 185px;
      height: 100%;

      .lang-list {
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;
        height: 100%;

        .langs {
          font-weight: bold;
          height: 25px;
          width: 100%;
          padding-left: 0.5rem;

          &.selected {
            color: $black;
            &:hover {
              background: $black;
              fill: $white;
              color: $white;

              a {
                color: $white;
                cursor: none;
              }
            }
          }

          &:hover {
            background: $black;

            a {
              color: $white;
            }
          }

          a:focus,
          a:focus-visible {
            span {
              outline: 2px solid $black;
            }
          }

          a {
            color: $black;
          }

          .lang-title {
            margin: 0;
            height: 100%;
            overflow: hidden;
            white-space: nowrap;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0 15px;

            .lang-check {
              max-height: 12px;
              max-width: 12px;
              margin-left: 0.35rem;
            }
          }
        }
      }
    }
  }
}

@include mobile() {
  .lang-slideout {
    position: absolute;
  }
}
